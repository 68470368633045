
import { defineComponent, ref, PropType } from 'vue'
import PcItem from './PcItem.vue'
import { useI18n } from 'vue-i18n'
import { everpayAppHost, isEverpayFarm } from '@/constants'
export default defineComponent({
  components: {
    PcItem
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    to: {
      type: String,
      default: ''
    },
    items: {
      type: [Array] as PropType<any[][]>,
      default: () => ([])
    },
    othersAppUrl: {
      type: Array as PropType<any[]>,
      default: () => ([])
    },
    routeName: {
      type: String,
      default: ''
    }
  },
  setup (props) {
    const navbarTextColorClass = isEverpayFarm
      ? 'text-everPurple9'
      : 'text-everPurple0'
    const navbarTextColorHoverClass = isEverpayFarm
      ? 'hover:text-everPurple9'
      : 'hover:text-everPurple0'
    const { t } = useI18n()
    const hoverd = ref(false)
    const titleClick = () => {
      if (props.to) {
        if (props.to.startsWith('https://')) {
          window.open(props.to)
        } else {
          window.open(`${everpayAppHost}${props.to}`)
        }
      }
    }
    return {
      hoverd,
      t,
      navbarTextColorHoverClass,
      navbarTextColorClass,
      titleClick
    }
  }
})
