
import { ref, computed, onMounted, watch, defineComponent, Ref } from 'vue'
import { useI18n } from 'vue-i18n'
import {
  isProd,
  everpayAppHost,
  everpayOfficialWebsiteHost,
  savedI18nStorageKey,
  isEverpayApp,
  isEverpayFarm,
  // everpayFarmHost,
  isEverpayOfficialWebsite,
  everpayScanHost,
  isMobile
} from '@/constants'
import { checkParentsHas } from '@/libs/util'
import MobileNavItem from './MobileNavItem.vue'
import PcNavItem from './PcNavItem.vue'

interface navItems {
  title: string
  routeNames: string[]
  to: string
  openNew?: boolean
  img: string
  activeImg: string
  items: any[]
  visible?: boolean
  select?: any
}
export default defineComponent({
  components: {
    MobileNavItem,
    PcNavItem
  },
  props: {
    scrollTop: {
      type: Number,
      default: 0
    },
    prefixLogo: {
      type: String,
      default: ''
    },
    btnText: {
      type: String,
      default: ''
    },
    btnStyleType: {
      type: String,
      default: 'primary'
    }
  },
  emits: ['handleLocaleChange', 'handleBtnClick', 'openCamera'],
  setup (props, context) {
    const currentClick = ref(-1)
    const copyAccountSuccessVisible = ref(false)
    const isLangWrapperTarget = checkParentsHas('lang-wrapper')

    const { t, locale } = useI18n({ useScope: 'global' }) as any
    const windowWidth = ref(document.documentElement.offsetWidth)
    const mobileNavModalOpened = ref(false)
    const languagesVisible = ref(false)
    const mobileWalletVisible = ref(false)
    const mobileBridgeVisible = ref(false)
    const mobileDappsVisible = ref(false)
    const mobileDevelopersVisible = ref(false)
    const securityOpened = ref(false)
    const navbarTextColorClass = isEverpayFarm
      ? 'text-everPurple9'
      : 'text-everPurple0'
    const navbarTextColorHoverClass = isEverpayFarm
      ? 'hover:text-everPurple9'
      : 'hover:text-everPurple0'
    const navbarBtnBorderClass = isEverpayFarm
      ? 'border-everPurple9'
      : 'border-everPurple0'
    const navbarBtnBackClass = isEverpayFarm
      ? 'connect-btn-bg-farm'
      : 'connect-btn-bg-normal'
    const navItems: Ref<navItems[]> = computed(() => {
      return [
        {
          title: 'discover_dapps',
          routeNames: isEverpayApp ? ['dapps'] : [],
          openNew: true,
          to: `${everpayAppHost}/dapps`,
          img: 'discover-dapps.svg',
          activeImg: 'discover-dapps-purple.svg',
          items: [{
            title: 'discover_dapps',
            openNew: true,
            routeNames: ['dapps'],
            to: `${everpayAppHost}/dapps`
          }, discoverDAppsItems.value[0][0], discoverDAppsItems.value[1][0], discoverDAppsItems.value[0][1]],
          visible: mobileDappsVisible.value,
          select: mobileActiveDapps
        },
        {
          title: 'deposit',
          routeNames: isEverpayApp ? ['deposit', 'withdraw'] : [],
          openNew: true,
          to: `${everpayAppHost}/deposit`,
          img: 'bridge.svg',
          activeImg: 'bridge-purple.svg',
          items: bridgeItems.value,
          visible: mobileBridgeVisible.value,
          select: mobileActiveBridge
        },
        {
          title: 'wallet',
          routeNames: isEverpayApp
            ? ['transfer', 'assets', 'transactions', 'contacts']
            : [],
          openNew: true,
          to: `${everpayAppHost}/`,
          img: 'wallet-black.svg',
          activeImg: 'wallet-purple.svg',
          items: walletItems.value,
          visible: mobileWalletVisible.value,
          select: mobileActiveWallet
        },
        {
          title: 'explorer',
          routeNames: [],
          openNew: true,
          to: everpayScanHost,
          img: 'explore.svg',
          activeImg: 'explore-purple.svg',
          items: []
        }
      ].filter(Boolean)
    })
    const navItems2:Ref<navItems[]> = computed(() => {
      return [
        {
          title: 'developers',
          routeNames: [],
          to: '',
          img: 'developers.svg',
          activeImg: 'developers-purple.svg',
          items: developerItems.value,
          visible: mobileDevelopersVisible.value,
          select: mobileActiveDevelopers
        }
      ]
    })
    const pcNavItems = computed(() => {
      return [
        {
          title: 'discover_dapps',
          routeNames: isEverpayApp ? ['dapps'] : [],
          openNew: !isEverpayApp,
          to: '/dapps',
          items: discoverDAppsItems.value
        },
        {
          title: 'developers',
          routeNames: [],
          openNew: !isEverpayApp,
          to: '',
          items: developerPcItems.value
        },
        {
          title: 'deposit',
          routeNames: isEverpayApp ? ['deposit', 'withdraw'] : [],
          openNew: !isEverpayApp,
          to: `${everpayAppHost}/deposit`,
          items: [bridgeItems.value]
        },
        {
          title: 'wallet',
          routeNames: isEverpayApp
            ? ['transfer', 'assets', 'transactions', 'contacts']
            : [],
          openNew: !isEverpayApp,
          to: `${everpayAppHost}/`,
          items: [walletItems.value]
        },
        {
          title: 'explorer',
          routeNames: [],
          openNew: true,
          to: everpayScanHost,
          img: 'explore.svg',
          activeImg: 'explore-purple.svg',
          items: []
        }
      ]
    })
    const bridgeItems = computed(() => {
      return [
        {
          title: 'deposit',
          openNew: true,
          routeNames: ['deposit'],
          to: `${everpayAppHost}/deposit`
        },
        {
          title: 'otc_title',
          openNew: true,
          routeNames: ['otcLegend'],
          to: `${everpayAppHost}/otc-legend`
        },
        {
          title: 'withdraw',
          openNew: true,
          routeNames: ['withdraw'],
          to: `${everpayAppHost}/withdraw`
        }
      ]
    })
    const walletItems = computed(() => {
      return [
        {
          title: 'wallet',
          openNew: true,
          routeNames: ['assets'],
          to: `${everpayAppHost}/`
        },
        {
          title: 'transfer',
          openNew: true,
          routeNames: ['transfer'],
          to: `${everpayAppHost}/transfer`
        },
        {
          title: 'contacts',
          openNew: true,
          routeNames: ['contacts'],
          to: `${everpayAppHost}/contacts`
        }
      ]
    })
    const resourceItems = computed(() => {
      return [
        // {
        //   title: 'v3_farm',
        //   openNew: !isEverpayFarm,
        //   to: everpayFarmHost
        // },
        {
          title: 'explorer',
          openNew: true,
          to: everpayScanHost
        },
        {
          title: 'light_paper',
          openNew: true,
          to: (locale.value as any).includes('zh')
            ? 'https://mirror.xyz/0x6451eB7f668de69Fb4C943Db72bCF2A73DeeC6B1/mafuxM9-S4pcHtXhwfLPjLkDCoWQxtQsyEn-mhmiXAQ'
            : 'https://mirror.xyz/0x6451eB7f668de69Fb4C943Db72bCF2A73DeeC6B1/aV1mucZp4JAG7j3PnZiL-RcnDBc1VIxBTp2QREqiCIQ'
        },
        {
          title: 'docs',
          openNew: true,
          to: `https://docs.everpay.io/${
            (locale.value as any).includes('zh') ? '' : 'en/'
          }`
        }
      ]
    })
    const whitePaperUrl = computed(() => {
      return (locale.value as any).includes('zh')
        ? 'https://mirror.xyz/0x6451eB7f668de69Fb4C943Db72bCF2A73DeeC6B1/mafuxM9-S4pcHtXhwfLPjLkDCoWQxtQsyEn-mhmiXAQ'
        : 'https://mirror.xyz/0x6451eB7f668de69Fb4C943Db72bCF2A73DeeC6B1/aV1mucZp4JAG7j3PnZiL-RcnDBc1VIxBTp2QREqiCIQ'
    })
    const developerItems = computed(() => {
      return [
        {
          title: 'developer_documentation',
          openNew: true,
          to: `https://docs.everpay.io/${
            (locale.value as any).includes('zh') ? '' : 'en/'
          }`
        },
        {
          title: 'developer_api_documentation',
          openNew: true,
          to: `https://docs.everpay.io/${
            (locale.value as any).includes('zh') ? '' : 'en/'
          }docs/guide/server-api/intro`
        },
        {
          title: 'developer_github',
          openNew: true,
          to: 'https://github.com/everFinance'
        },
        {
          title: 'developer_everPay_js',
          openNew: true,
          to: `https://docs.everpay.io/${
            (locale.value as any).includes('zh') ? '' : 'en/'
          }docs/guide/SDK/everpay-js/installation`
        },
        {
          title: 'developer_python_sdk',
          openNew: true,
          to: 'https://github.com/everFinance/everpay.py'
        },
        {
          title: 'light_paper',
          openNew: true,
          to: whitePaperUrl.value
        }
      ]
    })
    const developerPcItems = computed(() => {
      return [
        [
          {
            title: 'developer_documentation',
            openNew: true,
            to: `https://docs.everpay.io/${
              (locale.value as any).includes('zh') ? '' : 'en/'
            }`
          },
          {
            title: 'developer_api_documentation',
            openNew: true,
            to: `https://docs.everpay.io/${
              (locale.value as any).includes('zh') ? '' : 'en/'
            }docs/guide/server-api/intro`
          },
          {
            title: 'developer_github',
            openNew: true,
            to: 'https://github.com/everFinance'
          },
          {
            title: 'developer_everPay_js',
            openNew: true,
            to: 'https://github.com/everFinance/everpay-js'
          },
          {
            title: 'developer_python_sdk',
            openNew: true,
            to: 'https://github.com/everFinance/everpay.py'
          }
        ],
        [
          {
            title: 'light_paper',
            openNew: true,
            to: whitePaperUrl.value,
            img: 'white-paper.svg',
            hoverImg: 'white-paper-purple.svg'
          },
          {
            title: 'developer_collection_from',
            openNew: true,
            to: 'https://docs.google.com/forms/d/e/1FAIpQLSfhzibF7SCA16gm_i3_S0e5xHtjew_h2NfRZzmvr7opjh2UBw/viewform',
            img: 'collection-from.svg',
            hoverImg: 'collection-from-purple.svg'
          }
        ],
        [
          {
            title: 'developer_support',
            openNew: true,
            to: 'https://discord.gg/keMNQZY9p4',
            img: 'discord-3.svg',
            hoverImg: 'discord-purple.svg'
          }
        ]
      ]
    })
    const discoverDAppsItems = computed(() => {
      return [
        [
          {
            title: 'dapp_permaswap',
            openNew: true,
            to: `https://app${isProd ? '' : '-dev'}.permaswap.network/?lang=${locale.value}`
          },
          // {
          //   title: 'Arseeding',
          //   openNew: true,
          //   to: `https://web3infra.dev/${locale.value.includes('zh') ? 'zh-cn/' : ''}docs/arseeding/introduction/lightNode`
          // },
          {
            title: 'dapp_weweave',
            openNew: true,
            to: 'https://weweave.xyz/#/homepage'
          }
        ],
        [
          {
            title: 'dapp_aris',
            openNew: true,
            to: 'https://arisapp.arweave.dev/#/market'
          },
          // {
          //   title: 'Web3Infra',
          //   openNew: true,
          //   to: `https://web3infra.dev/${locale.value.includes('zh') ? 'zh-cn/' : ''}`
          // },
          // {
          //   title: 'PermaDAO',
          //   openNew: true,
          //   to: 'https://permadao.com/'
          // },
          {
            title: 'dicover_more',
            openNew: true,
            to: `${everpayAppHost}/dapps`
          }
        ]
      ]
    })
    const othersAppUrl = computed(() => {
      return [
        {
          img: 'twitter-2.svg',
          url: 'https://twitter.com/everVisionHQ'
        },
        {
          img: 'discord-2.svg',
          url: 'https://discord.gg/keMNQZY9p4'
        },
        {
          img: 'telegram-2.svg',
          url: 'https://t.me/FinanceEver'
        },
        {
          img: 'medium-2.svg',
          url: /^((?!chrome|android).)*safari/i.test(navigator.userAgent) ? 'https://medium.com/everfinance' : 'https://news.ever.vision'
        },
        {
          img: 'github-2.svg',
          url: 'https://github.com/everFinance'
        }
      ]
    })
    const mobileActiveWallet = (current:number) => {
      currentClick.value = current
      mobileWalletVisible.value = !mobileWalletVisible.value
      languagesVisible.value = false
    }
    const mobileActiveBridge = (current:number) => {
      currentClick.value = current
      mobileBridgeVisible.value = !mobileBridgeVisible.value
      languagesVisible.value = false
    }
    const mobileActiveDapps = (current:number) => {
      currentClick.value = current
      mobileDappsVisible.value = !mobileDappsVisible.value
      languagesVisible.value = false
    }
    const mobileActiveLanguage = (current:number) => {
      currentClick.value = current
      languagesVisible.value = !languagesVisible.value
      mobileWalletVisible.value = false
    }
    const mobileActiveDevelopers = (current:number) => {
      currentClick.value = current
      mobileDevelopersVisible.value = !mobileDevelopersVisible.value
      languagesVisible.value = false
    }
    const mobileNavItems = computed(() => {
      return navItems.value
        .filter((item) => item.title !== 'resources')
        .concat(resourceItems.value as any)
    })

    const languages = [
      {
        value: 'en',
        label: 'English'
      },
      {
        value: 'zh',
        label: '简体中文'
      }
    ]
    if (isEverpayApp || isEverpayOfficialWebsite) {
      languages.push(
        {
          value: 'es',
          label: 'Español'
        },
        {
          value: 'fr',
          label: 'Français'
        },
        {
          value: 'zh-TW',
          label: '繁體中文'
        }
      )
    }
    const scrollPickerOptions = [languages]
    const scrollPickerValues = ref([locale.value])

    watch(locale, () => {
      scrollPickerValues.value = [locale.value]
    })

    onMounted(async () => {
      window.addEventListener('resize', () => {
        windowWidth.value = document.documentElement.offsetWidth
        if (windowWidth.value >= 640) {
          mobileNavModalOpened.value = false
          languagesVisible.value = false
        }
      })

      window.addEventListener('scroll', () => {
        if (windowWidth.value >= 640) {
          languagesVisible.value = false
        }
      })

      document.addEventListener('click', (e) => {
        if (!isLangWrapperTarget(e.target as any)) {
          languagesVisible.value = false
        }
      })
    })
    const changeLocale = (lang: string) => {
      window.localStorage.setItem(savedI18nStorageKey, lang)
      locale.value = lang
      context.emit('handleLocaleChange', lang)
      mobileNavModalOpened.value = false
      languagesVisible.value = false
    }

    const confirmLocale = () => {
      locale.value = scrollPickerValues.value[0]
      context.emit('handleLocaleChange', locale.value)
      languagesVisible.value = false
      mobileNavModalOpened.value = false
    }

    const languagesHandleMouseOver = () => {
      languagesVisible.value = true
      mobileNavModalOpened.value = false
      closeMobileNavModal()
    }

    const closeMobileNavModal = () => {
      currentClick.value = -1
      mobileNavModalOpened.value = false
      mobileBridgeVisible.value = false
      mobileDappsVisible.value = false
      mobileWalletVisible.value = false
      mobileDevelopersVisible.value = false
    }

    return {
      t,
      isProd,
      isEverpayApp,
      isMobile,
      everpayOfficialWebsiteHost,
      locale,
      navItems,
      languagesVisible,
      languages,
      changeLocale,
      confirmLocale,
      mobileNavModalOpened,
      scrollPickerValues,
      scrollPickerOptions,
      resourceItems,
      mobileNavItems,
      navbarBtnBorderClass,
      navbarTextColorClass,
      navbarTextColorHoverClass,
      navbarBtnBackClass,
      copyAccountSuccessVisible,
      mobileActiveLanguage,
      closeMobileNavModal,
      currentClick,
      discoverDAppsItems,
      othersAppUrl,
      whitePaperUrl,
      securityOpened,
      pcNavItems,
      languagesHandleMouseOver,
      navItems2
    }
  }
})
