
import { defineComponent, PropType } from 'vue'
import { useI18n } from 'vue-i18n'
import { isMobile } from '@/constants'

interface navItems {
  title: string
  routeNames: string[]
  to: string
  openNew?: boolean
  img: string
  activeImg: string
  items: any[]
  visible?: boolean
  select?: any
}

export default defineComponent({
  components: {

  },
  props: {
    navItem: {
      type: Object as PropType<navItems>,
      default: () => {}
    },
    current: {
      type: Number,
      default: -1
    },
    isCurrentClick: {
      type: Boolean,
      default: false
    }
  },
  emits: ['closeMobileModal'],
  setup (props, context) {
    const { t } = useI18n()
    const click = (navItem:navItems) => {
      if (navItem.openNew) {
        window.open(navItem.to, '_blank')
      }
      context.emit('closeMobileModal')
    }
    return {
      t,
      isMobile,
      click
    }
  }
})
