export default {
  app: 'App',
  wallet: '钱包',
  bridge: '跨链',
  swap: '兑换',
  sdk: 'SDK',
  docs: '文档',
  otc_title: '一键买币🔥',
  resources: '资源',
  light_paper: '白皮书',
  NFT_auction: 'NFT拍卖',
  explorer: 'everScan',
  lang: '简体中文',
  done: '完成',
  launch_app: '立即体验',
  slogan: '实时代币协议',
  products: '产品',
  develop: '开发',
  v3_farm: '流动性挖矿',
  documentation: '文档',
  support: '用户支持',
  help_center: '帮助中心',
  privacy_policy: '隐私条款',
  faqs: '常见问题',
  about: '关于',
  coming_soon: '敬请期待',

  discover_dapps: '探索 DApp',
  deposit: '充值',
  withdraw: '提现',
  developer_documentation: '文档',
  developer_api_documentation: 'API 文档',
  developer_github: 'Github',
  developer_everPay_js: 'everPay-js',
  developer_python_sdk: 'Python SDK',
  terms2: '服务条款',
  privacy_policy_t2: '隐私条款',
  dicover_more: '探索更多',
  dapp_permaswap: 'Permaswap',
  dapp_weweave: 'Weweave',
  dapp_aris: 'ARIS',
  developer_collection_from: '项目收集',
  developer_support: '支持',
  transfer: '转账',
  contacts: '联系人',
  developers: '开发者',

  tradingdata: {
    total_volume: '累计交易量',
    total_trades: '交易笔数',
    total_wallets: '交易地址数',
    tvl: '总锁仓量',
    learn_more: '了解更多'
  },
  features: {
    problem_1: '转账需要等待？',
    title_1: '毫秒级转账',
    desc_1: '区别于传统区块链转账需等待打包，everPay 交易由 everPay 服务器验证并缓存，实现资产毫秒级到账。交易无需等待打包，服务器保障交易在数分钟内顺序上链。',
    problem_2: '昂贵的转账费用？',
    title_2: '无矿工费转账',
    desc_2: 'everPay 协议采用基于 Arweave 的确定性存储计算范式开发，everPay 的所有交易都在 Arweave 上永久存储，1 美金可以支持千万笔交易。现阶段用户转账，完全免费。',
    problem_3: '只有两条链？',
    title_3: '多链聚合',
    desc_3: 'everPay 协议的账户体系可以支持多种类型公链，目前已经支持 Ethereum, Arweave, Moonbeam, Conflux, BNB 链与 PlatON。未来，everPay 将聚合所有主流的链上资产的支付与结算。我们的目标是：多链聚合，互通无界。',
    problem_4: '还需要私钥？',
    title_4: '智能账户体系',
    desc_4: 'everPay 协议未来将支持传统互联网账号，通过 Webauthn（R1） 技术，everPay 可以将手机等硬件上的加密芯片作为账户的密钥。用户只需要一部手机或一个邮箱，即可畅游加密世界。'
  },
  architecture: {
    title: 'everPay 是如何运行的？',
    desc: 'everPay 协议通过 DAO 进行运作，完全透明和 100% 可验证。DAO 的数据共享和计算都采用了存储计算范式。DAO 中共有三类成员角色，分别是：协调者，检查者和守望者。'
  },
  coordinator: '协调者',
  coordinator_desc: '负责交易验证和上链',
  detector: '检测者',
  detector_desc: '自动下载和校验链上数据，生成全局状态和余额',
  watchman: '守望者',
  watchman_desc: '多签治理成员，生成提案和签署提案，提案只有获得了大多数（>51%）守望者的签署后才能执行',
  more_functions: {
    title: 'everPay 还提供什么？',
    cross: '跨链',
    cross_desc: '当前已经支持 AR 跨链至以太坊，未来还将支持更多资产跨链',
    swap: '兑换',
    swap_desc: '通过 everPay 批量转账，在多个对手方之间进行事务性兑换',
    financial_management: '理财',
    financial_management_desc: '跨链聚合理财，通过链下计算收集用户的理财需求，在一层进行一次性执行，极大地降低区块链理财成本'
  },
  develop_integration: {
    title: '开发者如何集成？',
    desc: '浏览 everPay 文档，立即集成',
    docs: 'Docs 文档',
    everpayjs: 'everpay-js'
  },
  not_found: '错误 404',
  not_found_desc: '当前页面不存在',
  back_to_home: '返回首页',
  terms: '服务条款',
  privacy_policy_t: '隐私条款',
  brand: '品牌',
  link: '友情链接 ',
  network_error: '网络繁忙，请稍后再试'
}
