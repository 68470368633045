export default {
  app: 'App',
  wallet: 'Portefeuille',
  bridge: 'Pont',
  swap: 'Swap',
  sdk: 'SDK',
  docs: 'Docs',
  otc_title: 'Acheter des cryptos🔥',
  resources: 'Resources',
  light_paper: 'Light Paper',
  NFT_auction: 'Enchères NFT',
  explorer: "L'explorateur",
  lang: 'Français',
  done: 'Terminé',
  launch_app: 'Portefeuille ouvert',
  slogan: 'Protocole financier en temps réel pour la blockchain',
  products: 'Produits',
  develop: 'Développer',
  v3_farm: 'Liquidity Mining',
  documentation: 'Documentation',
  support: 'Support',
  help_center: "Centre d'aide",
  privacy_policy: 'Politique de confidentialité',
  faqs: 'FAQ',
  about: 'À propos',
  coming_soon: 'bientôt disponible',

  discover_dapps: 'Découvrir les DApp',
  deposit: 'Dépôt',
  withdraw: 'Retirer',
  developer_documentation: 'Documentation',
  developer_api_documentation: 'API Documentation',
  developer_github: 'Github',
  developer_everPay_js: 'everPay-js',
  developer_python_sdk: 'Python SDK',
  terms2: 'Conditions d\'utilisation',
  privacy_policy_t2: 'Politique de confidentialité',
  dicover_more: 'Découvrir plus',
  dapp_permaswap: 'Permaswap',
  dapp_weweave: 'Weweave',
  dapp_aris: 'ARIS',
  developer_collection_from: 'Collection From',
  developer_support: 'Support',
  transfer: 'Transférer',
  contacts: 'Contacts',
  developers: 'Développeurs',

  tradingdata: {
    total_volume: 'VOLUME TOTAL',
    total_trades: 'TOTAL DES TRANSACTIONS',
    total_wallets: 'TOTAL DES PORTEFEUILLES',
    tvl: 'TVL',
    learn_more: 'En savoir plus'
  },
  features: {
    problem_1: 'Longtemps à attendre ?',
    title_1: 'Arrivée en temps réel',
    desc_1: "Contrairement aux blockchains traditionnelles où les transferts doivent attendre avant d'être emballés, les transactions everPay sont vérifiées et mises en cache par les serveurs everPay, ce qui fait que les actifs arrivent en temps réel. Il n'est pas nécessaire d'attendre que les transactions soient emballées, et le serveur garantit que les transactions sont téléchargées séquentiellement sur la chaîne en quelques minutes.",
    problem_2: 'Frais de transaction élevés?',
    title_2: 'Transfert gratuit',
    desc_2: 'Le protocole everPay suit le SCP (Storage-based consensus paradigm), Il n\'y a pas de frais de gaz requis, le stockage de toutes les transactions everPay via Arweave, avec un million de transactions pour un dollar. A ce stade, les utilisateurs transfèrent des actifs, totalement gratuitement.',
    problem_3: 'Deux Chaînes?',
    title_3: 'Interconnexion multi-chaînes',
    desc_3: 'Le système de compte du protocole everPay peut supporter plusieurs types de chaînes et supporte actuellement Ethereum, Arweave, Moonbeam, Conflux, BNB Chain, ainsi que PlatON. Au fur et à mesure que d\'autres chaînes seront ajoutées, everPay regroupera les actifs de toutes les principales chaînes, supportera plusieurs portefeuilles de blockchain et permettra le paiement et le règlement de plusieurs actifs sur everPay. Notre objectif est l\'interconnexion multi-chaînes, l\'interopérabilité sans frontières.',
    problem_4: 'Clé privée?',
    title_4: 'Compte intelligent',
    desc_4: 'Le protocole everPay prendra en charge les comptes Web2 traditionnels à l\'avenir. Avec la technologie Webauthn (R1), everPay peut utiliser la puce de cryptage sur le matériel tel que les téléphones portables comme clé du compte. Les utilisateurs n\'ont besoin que d\'un téléphone portable ou d\'une adresse électronique pour naviguer immédiatement dans le monde de la cryptographie.'
  },
  architecture: {
    title: 'Comment fonctionne everPay ?',
    desc: 'Le protocole everPay fonctionne via un DAO, qui est complètement transparent et 100% vérifiable. everPay utilise un paradigme informatique stocké pour le partage et le calcul des données. everPay a trois types de rôles de membre dans le DAO : coordinateur, détecteur et gardien.'
  },
  coordinator: 'Coordinateur',
  coordinator_desc: 'Responsable de la validation des transactions et de la liaison montante',
  detector: 'Détecteur',
  detector_desc: "Téléchargement et vérification automatiques des données en chain, génération d'états et de soldes globaux",
  watchman: 'Gardien',
  watchman_desc: 'Membres de gouvernance multi-signataires, générant des propositions et les signant, qui ne peuvent être mises en œuvre que si elles sont signées par une majorité (>51%) de veilleurs',
  more_functions: {
    title: "Qu'est-ce qu'EverPay propose d'autre ?",
    cross: 'Cross Chain',
    cross_desc: "Prend actuellement en charge AR cross-chain à ethereum, et prendra en charge plus d'actifs cross-chain à l'avenir",
    swap: 'Swap',
    swap_desc: 'Effectuez des échanges transactionnels entre plusieurs contreparties via la transaction groupée everPay',
    financial_management: 'Direction financière',
    financial_management_desc: 'Gestion de patrimoine agrégée de cross-chain, collecte des besoins financiers des utilisateurs via des calculs hors chain et exécution unique à un niveau, réduisant considérablement les coûts financiers de la blockchain'
  },
  develop_integration: {
    title: "Comment les développeurs s'intègrent-ils ?",
    desc: 'Parcourez la documentation everPay et intégrez-la maintenant',
    docs: 'Documentation',
    everpayjs: 'everpay-js'
  },
  not_found: 'Erreur 404',
  not_found_desc: 'Page non trouvée',
  back_to_home: "Retour à l'accueil",
  terms: 'Conditions',
  privacy_policy_t: 'Politique de confidentialité',
  brand: 'Marque',
  link: 'lien',
  network_error: 'Erreur de réseau, veuillez réessayer plus tard'
}
