export default {
  app: 'App',
  wallet: '錢包',
  bridge: '跨鏈',
  swap: '兌換',
  sdk: 'SDK',
  docs: '文檔',
  otc_title: '一鍵買幣🔥',
  resources: '資源',
  NFT_auction: 'NFT 拍賣',
  light_paper: '白皮書',
  explorer: 'everScan',
  lang: '繁體中文',
  done: '完成',
  launch_app: '立即體驗',
  slogan: '實時代幣協議',
  products: '產品',
  develop: '開發',
  v3_farm: '流動性挖礦',
  documentation: '文檔',
  support: '用戶支持',
  help_center: '幫助中心',
  privacy_policy: '隱私條款',
  faqs: '常見問題',
  about: '關於',
  coming_soon: '敬請期待',

  discover_dapps: '探索 DApp',
  deposit: '充值',
  withdraw: '提現',
  developer_documentation: '文档',
  developer_api_documentation: 'API 文档',
  developer_github: 'Github',
  developer_everPay_js: 'everPay-js',
  developer_python_sdk: 'Python SDK',
  terms2: '服務條款',
  privacy_policy_t2: '隱私條款',
  dicover_more: '探索更多',
  dapp_permaswap: 'Permaswap',
  dapp_weweave: 'Weweave',
  dapp_aris: 'ARIS',
  developer_collection_from: '項目收集',
  developer_support: '支持',
  transfer: '轉賬',
  contacts: '聯繫人',
  developers: '開發者',

  tradingdata: {
    total_volume: '累計交易量',
    total_trades: '交易筆數',
    total_wallets: '交易地址數',
    tvl: '總鎖倉量',
    learn_more: '了解更多'
  },
  features: {
    problem_1: '轉賬需要等待？',
    title_1: '毫秒級轉賬',
    desc_1: '區別於傳統區塊鏈轉賬需等待打包，everPay 交易由 everPay 服務器驗證並緩存，實現資產毫秒級到賬。交易無需等待打包，服務器保障交易在數分鐘內順序上鍊。',
    problem_2: '昂貴的轉賬費用？',
    title_2: '無礦工費轉賬',
    desc_2: 'everPay 協議採用基於 Arweave 的確定性存儲計算範式開發，everPay 的所有交易都在 Arweave 上永久存儲，1 美金可以支持千萬筆交易。現階段用戶轉賬，完全免費。',
    problem_3: '只有兩條鏈？',
    title_3: '多鏈聚合',
    desc_3: 'everPay 協議的賬戶體係可以支持多種類型公鏈，目前已經支持 Ethereum, Arweave, Moonbeam, Conflux, BNB 鏈與 PlatON。未來，everPay 將聚合所有主流的鏈上資產的支付與結算。我們的目標是：多鏈聚合，互通無界。',
    problem_4: '還需要私鑰？',
    title_4: '智能賬戶體系',
    desc_4: 'everPay 協議未來將支持傳統互聯網賬號，通過 Webauthn（R1） 技術，everPay 可以將手機等硬件上的加密芯片作為賬戶的密鑰。用戶只需要一部手機或一個郵箱，即可暢遊加密世界。'
  },
  architecture: {
    title: 'everPay 是如何運行的？',
    desc: 'everPay 協議通過 DAO 進行運作，完全透明和 100% 可驗證。 DAO 的數據共享和計算都採用了存儲計算範式。 DAO 中共有三類成員角色，分別是：協調者，檢查者和守望者。'
  },
  coordinator: '協調者',
  coordinator_desc: '負責交易驗證和上鍊',
  detector: '檢測者',
  detector_desc: '自動下載和校驗鏈上數據，生成全局狀態和余額',
  watchman: '守望者',
  watchman_desc: '多簽治理成員，生成提案和簽署提案，提案只有獲得了大多數（>51%）守望者的簽署後才能執行',
  more_functions: {
    title: 'everPay 還提供什麼？',
    cross: '跨鏈',
    cross_desc: '當前已經支持 AR 跨鏈至以太坊，未來還將支持更多資產跨鏈',
    swap: '兌換',
    swap_desc: '通過 everPay 批量轉賬，在多個對手方之間進行事務性兌換',
    financial_management: '理財',
    financial_management_desc: '跨鏈聚合理財，通過鏈下計算收集用戶的理財需求，在一層進行一次性執行，極大地降低區塊鏈理財成本'
  },
  develop_integration: {
    title: '開發者如何集成？',
    desc: '瀏覽 everPay 文檔，立即集成',
    docs: 'Docs 文檔',
    everpayjs: 'everpay-js'
  },
  not_found: '錯誤 404',
  not_found_desc: '當前頁面不存在',
  back_to_home: '返回首頁',
  terms: '服務條款',
  privacy_policy_t: '隱私條款',
  brand: '品牌',
  link: '友情鏈接',
  network_error: '網絡繁忙，請稍後再試'
}
