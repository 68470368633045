import { savedI18nStorageKey } from '../constants'
import { createI18n } from 'vue-i18n'
import en from './en'
import zh from './zh'
import fr from './fr'
import es from './es'
import zhTW from './zh-TW'
// import zendesk from '@/libs/zendesk'
const messages = {
  zh,
  en,
  fr,
  es,
  'zh-TW': zhTW
}

let lang = 'en';

['zh', 'en', 'fr', 'es', 'zh-TW'].forEach(item => {
  if (navigator.language.includes(item) || window.localStorage.getItem(savedI18nStorageKey) === item) {
    lang = item
  }
})

// zendesk.setLocale(lang as 'zh' | 'en')

// 通过选项创建 VueI18n 实例
export default createI18n({
  locale: lang, // 设置地区
  messages // 设置地区信息
})
