import { createApp } from 'vue'
import router from './router'
import App from './App.vue'
import { importElementPlus } from './element-plus'
import 'element-plus/theme-chalk/el-skeleton.css'
import 'element-plus/es/components/base/style/css'
import 'element-plus/theme-chalk/el-message.css'
import i18n from './i18n'
import './index.css'

const app = createApp(App)

app.use(i18n)
  .use(router)

importElementPlus(app)
app.mount('#app')
