import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "flex flex-row items-center justify-start hover:text-everPurple8 hover:text-opacity-100 transition-colors hover:bg-global-color/b/9 hover:bg-opacity-0.45 rounded-md h-9",
    onMouseover: _cache[1] || (_cache[1] = ($event: any) => (_ctx.hoverd = true)),
    onMouseleave: _cache[2] || (_cache[2] = ($event: any) => (_ctx.hoverd = false))
  }, [
    _createElementVNode("div", {
      class: "flex items-center h-full px-6 w-full",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.itemClick && _ctx.itemClick(...args)))
    }, [
      (_ctx.img && _ctx.hoverImg)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            src: require(`@/images/${_ctx.hoverd ? _ctx.hoverImg : _ctx.img}`),
            class: "mr-1",
            alt: _ctx.title
          }, null, 8, _hoisted_1))
        : _createCommentVNode("", true),
      _createTextVNode(" " + _toDisplayString(_ctx.t(_ctx.title)), 1)
    ])
  ], 32))
}