
import { defineComponent, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
export default defineComponent({
  props: {
    title: {
      type: String,
      default: ''
    },
    openNew: {
      type: Boolean,
      default: false
    },
    to: {
      type: String,
      default: ''
    },
    img: {
      type: String,
      default: ''
    },
    hoverImg: {
      type: String,
      default: ''
    }
  },
  setup (props) {
    const hoverd = ref(false)
    const { t } = useI18n()
    const router = useRouter()
    const itemClick = () => {
      console.log('propspropspropspropsprops', props)
      if (props.openNew) {
        window.open(props.to)
      } else {
        router.push(props.to)
      }
    }
    return {
      hoverd,
      t,
      itemClick
    }
  }
})
