export default {
  app: 'App',
  wallet: 'Wallet',
  bridge: 'Bridge',
  swap: 'Swap',
  sdk: 'SDK',
  docs: 'Docs',
  otc_title: 'Buy Crypto🔥',
  resources: 'Resources',
  light_paper: 'Light Paper',
  NFT_auction: 'NFT Auction',
  explorer: 'everScan',
  lang: 'English',
  done: 'Done',
  launch_app: 'Open Wallet',
  slogan: 'Real-time financial protocol for the blockchain',
  products: 'Products',
  develop: 'Develop',
  v3_farm: 'Liquidity Mining',
  documentation: 'Documentation',
  support: 'Support',
  help_center: 'Help Center',
  privacy_policy: 'Privacy Policy',
  faqs: 'FAQs',
  about: 'About',
  coming_soon: 'coming soon',

  discover_dapps: 'Discover DApp',
  deposit: 'Deposit',
  withdraw: 'Withdraw',
  developer_documentation: 'Documentation',
  developer_api_documentation: 'API Documentation',
  developer_github: 'Github',
  developer_everPay_js: 'everPay-js',
  developer_python_sdk: 'Python SDK',
  terms2: 'Terms of Service',
  privacy_policy_t2: 'Privacy Policy',
  dicover_more: 'Dicover more',
  dapp_permaswap: 'Permaswap',
  dapp_weweave: 'Weweave',
  dapp_aris: 'ARIS',
  developer_collection_from: 'Collection From',
  developer_support: 'Support',
  transfer: 'Transfer',
  contacts: 'Contacts',
  developers: 'Developers',

  tradingdata: {
    total_volume: 'TOTAL VOLUME',
    total_trades: 'TOTAL TRADES',
    total_wallets: 'TOTAL WALLETS',
    tvl: 'TVL',
    learn_more: 'Learn more'
  },
  features: {
    problem_1: 'Long Time To Wait?',
    title_1: 'Real-time Arrival',
    desc_1: 'Unlike traditional blockchains where transfers have a wait time in order to be packaged, everPay transactions are verified and cached by everPay servers making it that assets arrive in real-time. There is no need to wait for transactions to be packaged, and the server guarantees that transactions are sequentially uploaded to the chain within minutes.',
    problem_2: 'Expensive Transaction Fee?',
    title_2: 'Free Transfer',
    desc_2: 'everPay protocol follows the SCP (Storage-based consensus paradigm), There is no gas fee required, storing all everPay transactions via Arweave, with one million transactions for one dollar. At this stage users transfer assets, completely free of charge.',
    problem_3: 'Two Chains?',
    title_3: 'Multi-chain Interconnection',
    desc_3: 'The everPay protocol account system can support multiple types of chains and currently supports Ethereum, Arweave, Moonbeam, Conflux, BNB Chain, as well as PlatON. as more chains are added, everPay will aggregate assets from all major chains, support multiple blockchain wallets, and enable payment and settlement of multiple assets on everPay. Our goal is multi-chain interconnection, interoperability without borders.',
    problem_4: 'Private key?',
    title_4: 'Smart Account',
    desc_4: 'The everPay protocol will support traditional Web2 accounts in the future. With Webauthn (R1) technology, everPay can use the encryption chip on hardware such as cell phones as the key to the account. All users need is a cell phone or an email address to immediately navigate the crypto world.'
  },
  architecture: {
    title: 'How does everPay work?',
    desc: 'The everPay protocol operates through a DAO, which is completely transparent and 100% verifiable. everPay uses a stored computing paradigm for both data sharing and computation. everPay has three types of member roles in the DAO: Coordinator, Detector, and Watchman.'
  },
  coordinator: 'Coordinator',
  coordinator_desc: 'Responsible for transaction validation and uplinking',
  detector: 'Detector',
  detector_desc: 'Automatic download and verification of on-chain data, generation of global status and balances',
  watchman: 'Watchman',
  watchman_desc: 'Multi-signatory governance members, generating proposals and signing them, which can only be implemented if they are signed by a majority (>51%) of watchman',
  more_functions: {
    title: 'What else does everPay offer?',
    cross: 'Cross Chain',
    cross_desc: 'Currently supports AR cross-chain to ethereum, and will support more assets cross-chain in the future',
    swap: 'Swap',
    swap_desc: 'Make transactional exchanges between multiple counterparties via everPay bundle transaction',
    financial_management: 'Financial Management',
    financial_management_desc: "Cross-chain aggregated wealth management, collecting users' financial needs through off-chain calculations and performing one-time execution at one level, greatly reducing blockchain financial costs"
  },
  develop_integration: {
    title: 'How do developers integrate?',
    desc: 'Browse everPay documentation and integrate now',
    docs: 'Documentation',
    everpayjs: 'everpay-js'
  },
  not_found: 'Error 404',
  not_found_desc: 'Page Not Found',
  back_to_home: 'Back to homepage',
  terms: 'Terms',
  privacy_policy_t: 'Privacy Policy',
  brand: 'Brand',
  link: 'Link',
  network_error: 'Network error, please try again later'
}
