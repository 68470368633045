
import { useI18n } from 'vue-i18n'
import { computed, defineComponent } from 'vue'
import {
  isMobile,
  isEverpayApp,
  everpayAppHost,
  isEverpayScan,
  everpayScanHost,
  // isEverpayFarm,
  // everpayFarmHost,
  isProd
} from '@/constants'

export default defineComponent({
  setup () {
    const socials = [
      {
        link: 'https://twitter.com/everPayHQ',
        name: 'twitter'
      },
      {
        link: 'https://discord.gg/keMNQZY9p4',
        name: 'discord'
      },
      {
        link: 'https://t.me/FinanceEver',
        name: 'telegram'
      },
      {
        link: /^((?!chrome|android).)*safari/i.test(navigator.userAgent) ? 'https://medium.com/everfinance' : 'https://news.ever.vision',
        name: 'medium'
      },
      {
        link: 'https://github.com/everFinance',
        name: 'github'
      }
    ]

    const { t, locale } = useI18n()

    const getComputedFooterEntries = (t: any, locale: string) => {
      return [
        {
          title: t('products'),
          items: [
            {
              title: t('wallet'),
              to: isEverpayApp ? '/' : '',
              link: everpayAppHost
            },
            {
              title: t('discover_dapps'),
              to: isEverpayApp ? '/dapps' : '',
              link: `https://app${!isProd ? '-dev' : ''}.everpay.io/dapps`
            },
            {
              title: t('deposit'),
              to: isEverpayApp ? '/deposit' : '',
              link: `https://app${!isProd ? '-dev' : ''}.everpay.io/deposit`
            },
            {
              title: t('withdraw'),
              to: isEverpayApp ? '/withdraw' : '',
              link: `https://app${!isProd ? '-dev' : ''}.everpay.io/withdraw`
            },
            {
              title: t('otc_title'),
              to: isEverpayApp ? '/otc-legend' : '',
              link: `https://app${!isProd ? '-dev' : ''}.everpay.io/otc-legend`
            },
            // {
            //   title: t('NFT_auction'),
            //   to: isEverpayApp ? '/nft-auction' : '',
            //   link: `${everpayAppHost}/nft-auction`
            // },
            // {
            //   title: t('v3_farm'),
            //   to: isEverpayFarm ? '/' : '',
            //   link: `${everpayFarmHost}`
            // },
            {
              title: t('explorer'),
              to: isEverpayScan ? '/' : '',
              link: everpayScanHost
            }
          ]
        },
        {
          title: t('develop'),
          items: [
            {
              title: t('documentation'),
              link: `https://docs.everpay.io/${locale.includes('zh') ? '' : 'en'}`
            },
            {
              title: t('developer_api_documentation'),
              link: `https://docs.everpay.io/${
                (locale as any).includes('zh') ? '' : 'en/'
              }docs/guide/server-api/intro`
            },
            {
              title: t('developer_github'),
              link: 'https://github.com/everFinance'
            },
            {
              title: 'everpay-js',
              link: 'https://github.com/everFinance/everpay-js'
            },
            {
              title: t('developer_python_sdk'),
              link: 'https://github.com/everFinance/everpay.py'
            }
          ]
        },
        {
          title: t('support'),
          items: [
            {
              title: t('help_center'),
              link: `https://everpay.zendesk.com/hc/${locale.includes('zh') ? 'zh-cn' : 'en-us'}`
            },
            {
              title: t('faqs'),
              link: locale.includes('zh')
                ? 'https://everpay.zendesk.com/hc/zh-cn/categories/4403074774809-%E5%B8%B8%E8%A7%81%E9%97%AE%E9%A2%98'
                : 'https://everpay.zendesk.com/hc/en-us/categories/4403074774809-FAQs'
            }
          ]
        },
        {
          title: t('about'),
          items: [
            {
              title: t('light_paper'),
              link: locale.includes('zh')
                ? 'https://mirror.xyz/0x6451eB7f668de69Fb4C943Db72bCF2A73DeeC6B1/mafuxM9-S4pcHtXhwfLPjLkDCoWQxtQsyEn-mhmiXAQ'
                : 'https://mirror.xyz/0x6451eB7f668de69Fb4C943Db72bCF2A73DeeC6B1/aV1mucZp4JAG7j3PnZiL-RcnDBc1VIxBTp2QREqiCIQ'
            },
            {
              title: t('brand'),
              link: 'https://permadao.notion.site/everVision-Brand-Home-413292caf9674f26bdd873bcbf400854?pvs=4'
            },
            {
              title: 'everVision',
              link: 'https://ever.vision/#/'
            }
          ]
        }
      ]
    }

    const footerEntries = computed(() => {
      return getComputedFooterEntries(t, locale.value)
    }) as any

    const year = new Date().getFullYear()
    const terms = 'https://3xhq44z72lnvyrqwounyr6niks3222u55fbxzgxqn4eivnn4slja.arweave.net/3c8Ocz_S21xGFnUbiPmoVLetap3pQ3ya8G8IirW8ktI'
    const privacyPolicyUrl = 'https://eokptpbng3aag6gn7bgpe2mjfhtedjslj44xgm7bpxqtghnc43rq.arweave.net/I5T5vC02wAN4zfhM8mmJKeZBpktPOXMz4X3hMx2i5uM'
    const aboutBrandUrl = computed(() => {
      return [
        {
          img: 'permaDAO.svg',
          link: 'https://permadao.com/'
        },
        {
          img: 'permaswap.svg',
          link: isProd ? 'https://permaswap.network/#/' : 'https://dev.permaswap.network/#/'
        },
        {
          img: 'w3I.svg',
          link: 'https://web3infra.dev/'
        },
        {
          img: 'permaweb.svg',
          link: 'https://awesome-permaweb.arweave.asia/'
        }
      ]
    })
    return {
      isMobile,
      footerEntries,
      socials,
      year,
      t,
      aboutBrandUrl,
      privacyPolicyUrl,
      terms
    }
  }
})

