export default {
  app: 'Aplicación',
  wallet: 'Cartera',
  bridge: 'Puente',
  swap: 'Swap',
  sdk: 'SDK',
  docs: 'Docs',
  otc_title: 'Comprar Criptos🔥',
  resources: 'Recursos',
  light_paper: 'Light Paper',
  NFT_auction: 'Subasta NFT',
  explorer: 'Explorador',
  lang: 'Español',
  done: 'Completado',
  launch_app: 'Abrir Cartera',
  slogan: 'Protocolo financiero en tiempo real para la blockchain',
  products: 'Productos',
  develop: 'Desarrollar',
  v3_farm: 'Minería de liquidez',
  documentation: 'Documentación',
  support: 'Apoyo',
  help_center: 'Centro de Ayuda',
  privacy_policy: 'Política de privacidad',
  faqs: 'FAQs',
  about: 'Sobre',
  coming_soon: 'próximamente',

  discover_dapps: 'Discover DApp',
  deposit: 'Depositar',
  withdraw: 'Retirar',
  developer_documentation: 'Documentación',
  developer_api_documentation: 'API Documentación',
  developer_github: 'Github',
  developer_everPay_js: 'everPay-js',
  developer_python_sdk: 'Python SDK',
  terms2: 'Condiciones del servicio',
  privacy_policy_t2: ' Política de privacidad',
  dapp_permaswap: 'Permaswap',
  dapp_weweave: 'Weweave',
  dapp_aris: 'ARIS',
  dicover_more: 'Más información',
  developer_collection_from: 'Colección de',
  developer_support: 'Soporte',
  transfer: 'Transferir',
  contacts: 'Contactos',
  developers: 'Desarrolladores',

  tradingdata: {
    total_volume: 'VOLUMEN TOTAL',
    total_trades: 'TOTAL NEGOCIACIONES',
    total_wallets: 'TOTAL MONEDEROS',
    tvl: 'TVL',
    learn_more: 'Más información'
  },
  features: {
    problem_1: '¿Mucho tiempo de espera?',
    title_1: 'Llegada en tiempo real',
    desc_1: 'A diferencia de las blockchains tradicionales donde las transferencias tienen un tiempo de espera para ser empaquetadas, las transacciones everPay son verificadas y almacenadas en caché por los servidores everPay haciendo que los activos lleguen en tiempo real. No es necesario esperar a que las transacciones se empaqueten, y el servidor garantiza que las transacciones se suben secuencialmente a la cadena en cuestión de minutos.',
    problem_2: '¿Cargo por Transacción?',
    title_2: 'Transferencia Gratuita',
    desc_2: 'El protocolo everPay sigue el SCP (paradigma de consenso basado en el almacenamiento), No hay cuota de gas requerida, almacenando todas las transacciones everPay a través de Arweave, con un millón de transacciones por un dólar. En esta etapa los usuarios transfieren activos, de forma totalmente gratuita.',
    problem_3: '¿Dos Cadenas?',
    title_3: 'Interconexión Multicadena',
    desc_3: 'El sistema de cuentas de protocolo everPay puede soportar múltiples tipos de cadenas y actualmente soporta Ethereum, Arweave, Moonbeam, Conflux, BNB Chain, así como PlatON. A medida que se añadan más cadenas, everPay agregará activos de todas las cadenas principales, soportará múltiples monederos blockchain, y permitirá el pago y la liquidación de múltiples activos en everPay. Nuestro objetivo es la interconexión multicadena, la interoperabilidad sin fronteras.',
    problem_4: '¿Clave privada?',
    title_4: 'Cuenta inteligente',
    desc_4: 'El protocolo everPay soportará cuentas Web2 tradicionales en el futuro. Con la tecnología Webauthn (R1), everPay puede utilizar el chip de cifrado de hardware, como los teléfonos móviles, como clave de la cuenta. Todo lo que necesitan los usuarios es un teléfono móvil o una dirección de correo electrónico para navegar inmediatamente por el mundo criptográfico.'
  },
  architecture: {
    title: '¿Cómo funciona everPay?',
    desc: 'El protocolo everPay opera a través de un DAO, que es completamente transparente y 100% verificable. everPay utiliza un paradigma de computación almacenada tanto para el intercambio de datos como para la computación. everPay tiene tres tipos de roles de miembros en el DAO: Coordinador, Detector y Vigilante.'
  },
  coordinator: 'Coordinator',
  coordinator_desc: 'Responsable de la validación de transacciónes y el enlace ascendente',
  detector: 'Detector',
  detector_desc: 'Descarga y verificación automática de datos en la chain, generación de estados y balances globales',
  watchman: 'Guardián',
  watchman_desc: 'Integrantes de gobernanza multirrutantes, generando propuestas y suscribiéndolas, que solo pueden ser implementadas si están firmadas por una mayoría (> 51%) de los vigilantes.',
  more_functions: {
    title: '¿Qué más ofrece EverPay?',
    cross: 'Cross-Chain',
    cross_desc: 'Actualmente es compatible con la cross-chain de AR a ethereum, y admitirá más cross-chain de activos en el futuro',
    swap: 'Swap',
    swap_desc: 'Realice intercambios transaccionales entre varias contrapartes a través de la transacción de paquete everPay',
    financial_management: 'Gestión financiera',
    financial_management_desc: 'Gestión de riqueza agregada entre chains, recopilando las necesidades financieras de los usuarios a través de cálculos fuera de la cadena y realizando una ejecución única en un nivel, lo que reduce en gran medida los costos financieros de la cadena de bloques.'
  },
  develop_integration: {
    title: '¿Cómo se integran los desarrolladores?',
    desc: 'Explore la documentación de everPay e integre ahora',
    docs: 'Documentación',
    everpayjs: 'everpay-js'
  },
  not_found: 'Error 404',
  not_found_desc: 'Página no encontrada',
  back_to_home: 'Volver a la página de inicio',
  terms: 'Términos',
  privacy_policy_t: 'Política de privacidad',
  brand: 'Marca',
  link: 'Enlace',
  network_error: 'Error de red, inténtelo de nuevo más tarde'
}
