import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "w-full text-14px font-normal flex items-center cursor-pointer text-black text-opacity-0.85 mb-1.5" }
const _hoisted_2 = { class: "flex items-center" }
const _hoisted_3 = ["src"]
const _hoisted_4 = ["src"]
const _hoisted_5 = ["target", "href", "onClick"]
const _hoisted_6 = ["to", "onClick"]
const _hoisted_7 = ["target", "href"]
const _hoisted_8 = ["src"]
const _hoisted_9 = ["to"]
const _hoisted_10 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.navItem.items.length)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["transition-colors w-full", `${
        _ctx.navItem.visible && _ctx.isCurrentClick
          ? 'text-everPurple8'
          : ''
      }`])
        }, [
          _createElementVNode("div", {
            class: "flex items-center justify-between px-4",
            style: {"height":"38px"},
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.navItem.select ? _ctx.navItem.select(_ctx.current) : ''))
          }, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("img", {
                src: require(`@/images/${ _ctx.navItem.visible && _ctx.isCurrentClick ? _ctx.navItem.activeImg : _ctx.navItem.img}`),
                class: "mr-1 w-5 h-5",
                alt: "account-info"
              }, null, 8, _hoisted_3),
              _createTextVNode(" " + _toDisplayString(_ctx.t(_ctx.navItem.title)), 1)
            ]),
            _createElementVNode("img", {
              src: require(`@/images/${_ctx.navItem.visible && _ctx.isCurrentClick ? 'arrow-angle-down-purple' : 'arrow-angle-down'}.svg`),
              alt: "arrow-angle-down",
              class: _normalizeClass(["transform transition-all duration-300 w-5", _ctx.navItem.visible && _ctx.isCurrentClick ? 'rotate-180' : 'rotate-0 opacity-0.65'])
            }, null, 10, _hoisted_4)
          ]),
          _createElementVNode("ul", {
            class: "text-black text-opacity-0.65 transition-all duration-300 overflow-hidden",
            style: _normalizeStyle(`height: ${_ctx.navItem.visible ? `${(_ctx.navItem.items.length * 44)}px` : '0px'}`)
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.navItem.items, (item, navIndex) => {
              return (_openBlock(), _createElementBlock("li", {
                key: navIndex,
                class: "w-full cursor-pointer hover:bg-everPurple12 hover:bg-opacity-0.45 mt-1.5",
                style: {"height":"38px"}
              }, [
                (item.openNew)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      class: "w-full hover:text-everPurple8 pl-10 h-full flex items-center",
                      target: !_ctx.isMobile && item.to ? '_blank' : '',
                      href: item.to ? item.to : 'javascript:void(0);',
                      onClick: ($event: any) => (_ctx.click(item))
                    }, _toDisplayString(_ctx.t(item.title)), 9, _hoisted_5))
                  : (_openBlock(), _createElementBlock("div", {
                      key: 1,
                      to: item.to,
                      class: "transition-colors w-full hover:text-everPurple8 pl-10 h-full flex items-center",
                      onClick: ($event: any) => (_ctx.click(item))
                    }, _toDisplayString(_ctx.t(item.title)), 9, _hoisted_6))
              ]))
            }), 128))
          ], 4)
        ], 2))
      : (_ctx.navItem.openNew)
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            style: {"height":"38px"},
            class: "px-4 w-full flex items-center hover:bg-everPurple12 hover:bg-opacity-0.45",
            target: !_ctx.isMobile && _ctx.navItem.to ? '_blank' : '',
            href: _ctx.navItem.to ? _ctx.navItem.to : 'javascript:void(0);',
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.click(_ctx.navItem)))
          }, [
            _createElementVNode("img", {
              src: require(`@/images/${_ctx.navItem.img}`),
              class: "mr-1 w-5 h-5",
              alt: "account-info"
            }, null, 8, _hoisted_8),
            _createTextVNode(" " + _toDisplayString(_ctx.t(_ctx.navItem.title)), 1)
          ], 8, _hoisted_7))
        : (_openBlock(), _createElementBlock("div", {
            key: 2,
            to: _ctx.navItem.to,
            style: {"height":"38px"},
            class: "transition-colors w-full flex items-center px-4 hover:bg-everPurple12 hover:bg-opacity-0.45",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.click(_ctx.navItem)))
          }, [
            _createElementVNode("img", {
              src: require(`@/images/${_ctx.navItem.img}`),
              class: "mr-1 w-5 h-5",
              alt: "account-info"
            }, null, 8, _hoisted_10),
            _createTextVNode(" " + _toDisplayString(_ctx.t(_ctx.navItem.title)), 1)
          ], 8, _hoisted_9))
  ]))
}